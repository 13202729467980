import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import DataTable from "../../components/DataTable";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  query,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  collection,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Edit } from "@mui/icons-material";
import FilterText from "../../utils/FilterText";
import { useRef } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";

export default function Plans() {
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [message, setMessage] = useState("");
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [formData, setFormData] = useState({});
  const [addFormData, setAddFormData] = useState({});

  const originalDataRef = useRef([]);

  const columns = [
    { field: "listingOrder", headerName: "Listing Order", minWidth: 70, align: "left" },
    { field: "planCode", headerName: "Plan Code", minWidth: 170, align: "left" },
    { field: "plan", headerName: "Plan Name", minWidth: 170, align: "left" },
    {
      field: "price",
      headerName: "Price",
      minWidth: 130,
      align: "left",
    },
    {
      field: "additionalPlanPrice",
      headerName: "Additional User Price",
      minWidth: 150,
      align: "left",
    },
    {
      field: "productPermissions",
      headerName: "Product Permissions",
      minWidth: 200,
      align: "right",
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      headerName: "Actions",
      minWidth: 170,
      align: "right",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEdit(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDelete(params.row)}
        />,
      ]
    },
  ];

  const handleEdit = (data) => {
    setEditing(!editing);
    setFormData(data);
  };

  const handleChange = (e) => {
    if (e.target.name === "productPermissions") {

      setFormData(prevData => {
        if (formData.productPermissions?.length > 0) {
          if (!e.target.checked) {
            return { ...prevData, [e.target.name]: prevData.productPermissions instanceof Array ? prevData.productPermissions.filter(fd => fd !== e.target.value) : prevData.productPermissions.split(",").filter(fd => fd !== e.target.value) }
          }
          return { ...prevData, [e.target.name]: formData.productPermissions instanceof Array ? [...formData.productPermissions, e.target.value] : [...formData.productPermissions.split(","), e.target.value] }
        } else {
          return { ...prevData, [e.target.name]: [e.target.value] }
        }

      });
    } else {
      setFormData((prevData) => {
        return { ...prevData, [e.target.name]: e.target.value };
      });
    }
  };

  const handleAddChange = (e) => {
    if (e.target.name === "productPermissions") {
      setAddFormData(prevData => {
        if (addFormData.productPermissions?.length > 0) {
          if (!e.target.checked) {
            return { ...prevData, [e.target.name]: prevData.productPermissions.filter(fd => fd !== e.target.value) }
          }
          return { ...prevData, [e.target.name]: [...addFormData.productPermissions, e.target.value] }
        } else {
          return { ...prevData, [e.target.name]: [e.target.value] }
        }

      });
    } else {
      setAddFormData((prevData) => {
        return { ...prevData, [e.target.name]: e.target.value };
      });
    }
  };

  const handleShowCreate = async (e) => {
    e.preventDefault();
    setAdding(!adding);
  };

  const fetchAllProducts = async () => {
    const q = query(collection(db, "Websites", "Client", "Products"), orderBy("productName", "asc"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    if (querySnapshotSize > 0) {
      snapshots.forEach((doc) => {
        //FORMATE DATE OF JOINING START

        if (doc.data()) {
          let {
            accessPlatform,
            productName,
            productCode,
            description,
            productValidity,
            credits,
          } = doc.data();

          records.push({
            id: doc.id,
            accessPlatform: accessPlatform instanceof Array ? accessPlatform.join(",") : accessPlatform,
            productName,
            productCode,
            description,
            productValidity,
            credits,
          });
        }

        if (++i === querySnapshotSize) {
          setProducts(records);
          originalDataRef.current = records;
        }
      });
    } else {
      setProducts([]);
    }

  };

  const handleCreate = async (e) => {
    e.preventDefault();

    let benefits = addFormData.benefits.replaceAll("\n", "");

    try {
      let docRef = collection(db, "Websites", "Client", "Plans");
      await addDoc(docRef, {
        ...addFormData,
        listingOrder: Number(addFormData.listingOrder),
        benefits: benefits.indexOf(",") !== -1 ? benefits.split(",") : benefits.trim(),
      });

      setAdding(!adding);
      setAddFormData({});
      setMessage("");
      fetchAllPlans();
    } catch (err) {
      console.log(err);
      setMessage(err.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    let benefits = formData.benefits.replaceAll("\n", "");

    try {
      let docRef = doc(db, "Websites", "Client", "Plans", formData.id);

      await setDoc(docRef, {
        ...formData,
        listingOrder: Number(formData.listingOrder),
        benefits: benefits.indexOf(",") !== -1 ? benefits.split(",") : benefits.trim(),
      });

      setEditing(!editing);
      setMessage("");
      setFormData({});
      fetchAllPlans();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async ({ id }) => {
    try {
      let docRef = doc(db, "Websites", "Client", "Plans", id);
      await deleteDoc(docRef);
      fetchAllPlans();
    } catch (error) {
      setMessage(FilterText(error.message));
    }
  };

  //CRUD PLANS START
  const fetchAllPlans = async () => {
    const q = query(collection(db, "Websites", "Client", "Plans"), orderBy("listingOrder", "asc"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    if (querySnapshotSize > 0) {
      snapshots.forEach((doc) => {
        //FORMATE DATE OF JOINING START

        if (doc.data()) {
          let {
            benefits,
            productPermissions,
            listingOrder,
            plan,
            price,
            additionalPlanPrice,
            planCode
          } = doc.data();

          records.push({
            id: doc.id,
            productPermissions: productPermissions instanceof Array ? productPermissions?.join(",") : productPermissions,
            price,
            additionalPlanPrice,
            plan,
            benefits: benefits instanceof Array ? benefits.join(",") : benefits,
            listingOrder,
            planCode,
          });
        }

        if (++i === querySnapshotSize) {
          setData(records);
          originalDataRef.current = records;
        }
      });
    } else {
      setData([]);
    }

  };

  useEffect(() => {
    fetchAllPlans();
    fetchAllProducts();
  }, [adding, editing]);

  return (
    <div className="flex w-screen">
      <Sidebar />
      {adding && (
        <div className="body w-64 flex-1 bg-gray-200 p-8 overflow-y-scroll h-screen">
          <p className="mb-4 w-full text-center font-sans text-xl text-red-500">
            {FilterText(message)}
          </p>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Add New Plan Information
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Create plans for reseller or user
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={handleCreate}>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="listingOrder"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Listing Order
                        </label>
                        <input
                          required
                          type="number"
                          name="listingOrder"
                          id="listingOrder"
                          value={addFormData.listingOrder}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="planCode"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Plan Code
                        </label>
                        <input
                          required
                          type="text"
                          name="planCode"
                          id="planCode"
                          value={addFormData.planCode}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="plan"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Plan Name
                        </label>
                        <input
                          required
                          type="text"
                          name="plan"
                          id="plan"
                          value={addFormData.plan}
                          onChange={handleAddChange}
                          className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Price
                        </label>
                        <input
                          required
                          type="number"
                          name="price"
                          id="price"
                          value={addFormData.price}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="additionalPlanPrice"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Additional User Price
                        </label>
                        <input
                          required
                          type="number"
                          name="additionalPlanPrice"
                          id="additionalPlanPrice"
                          value={addFormData.additionalPlanPrice}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      {
                        products.length > 0 && (<div className="col-span-6 md:col-span-3 sm:col-span-2">
                          <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">Select Product</h3>
                          <ul className="overflow-y-scroll h-28 w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                            {
                              products.map(product => (<li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600" key={product.id}>
                                <div className="flex items-center pl-3">
                                  <input
                                    name="productPermissions"
                                    id={product.id}
                                    type="checkbox"
                                    value={product.productName}
                                    onChange={handleAddChange}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                  <label htmlFor={product.id} className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{product.productName}</label>
                                </div>
                              </li>)
                              )
                            }
                          </ul>
                        </div>)
                      }
                      <div className="col-span-6">
                        <label
                          htmlFor="benefits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Benefits
                        </label>
                        <textarea
                          name="benefits"
                          id="benefits"
                          cols="30"
                          rows="10"
                          value={addFormData.benefits}
                          onChange={handleAddChange}
                          autoComplete="amount"
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      value="Submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Add
                    </button>
                    <button
                      type="submit"
                      value="Submit"
                      onClick={() => setAdding(false)}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {editing && (
        <div className="body w-64 flex-1 bg-gray-200 p-8 overflow-y-scroll h-screen">
          <p className="mb-4 w-full text-center font-sans text-xl text-red-500">
            {FilterText(message)}
          </p>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Plans Information
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Update plans for reseller or user
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={handleUpdate}>
                <input
                  required
                  type="hidden"
                  name="id"
                  id="id"
                  defaultValue={formData.id}
                  autoComplete="id"
                />
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="order"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Listing Order
                        </label>
                        <input
                          required
                          type="number"
                          name="listingOrder"
                          id="listingOrder"
                          value={formData.listingOrder}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="planCode"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Plan Code
                        </label>
                        <input
                          required
                          type="text"
                          name="planCode"
                          id="planCode"
                          value={formData.planCode}
                          // onChange={handleChange}
                          readOnly
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="plan"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Plan Name
                        </label>
                        <input
                          required
                          type="text"
                          name="plan"
                          id="plan"
                          value={formData.plan}
                          // onChange={handleChange}
                          readOnly
                          className="mt-1 w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Price
                        </label>
                        <input
                          required
                          type="number"
                          name="price"
                          id="price"
                          value={formData.price}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="additionalPlanPrice"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Additional User Price
                        </label>
                        <input
                          required
                          type="number"
                          name="additionalPlanPrice"
                          id="additionalPlanPrice"
                          value={formData.additionalPlanPrice}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      {
                        products.length > 0 && (<div className="col-span-6 md:col-span-3 sm:col-span-2">
                          <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">Select Product</h3>
                          <ul
                            className="overflow-y-scroll h-28 w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                            {
                              products.map(product => (<li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600" key={product.id}>
                                <div className="flex items-center pl-3">
                                  <input
                                    name="productPermissions"
                                    id={product.id}
                                    type="checkbox"
                                    value={product.productName}
                                    onChange={handleChange}
                                    checked={formData.productPermissions instanceof Array ? formData.productPermissions.some(fs => fs === product.productName) : formData?.productPermissions?.includes(product.productName)}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                  <label htmlFor={product.id} className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{product.productName}</label>
                                </div>
                              </li>)
                              )
                            }
                          </ul>
                        </div>)
                      }
                      <div className="col-span-6">
                        <label
                          htmlFor="benefits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Benefits
                        </label>
                        <textarea
                          name="benefits"
                          id="benefits"
                          cols="30"
                          rows="5"
                          value={formData.benefits}
                          onChange={handleChange}
                          autoComplete="amount"
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      value="Submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Update
                    </button>
                    <button
                      type="submit"
                      value="Submit"
                      onClick={() => setEditing(false)}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {data && !editing && !adding && (
        <div className="body w-64 flex-1 bg-white p-8">
          <h1 className="mb-4 rounded bg-purple-400 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
            PLANS
          </h1>
          {
            !adding &&
            !editing && (
              <div className="flex justify-end">
                <button className="bg-blue-400 text-white px-2 py-3 rounded-full" onClick={handleShowCreate}>
                  ADD
                </button>
              </div>
            )
          }
          <DataTable columns={columns} data={data} />
        </div>
      )}
    </div>
  );
}
