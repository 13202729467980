import { Upload } from "@mui/icons-material";
import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL, getStorage, deleteObject } from "firebase/storage";
import {
  addDoc,
  doc,
  collection,
  getDocs,
  query,
  deleteDoc,
} from "firebase/firestore";

import Alerts from "../../components/Alerts"

import FilterText from "../../utils/FilterText";
import { useCallback } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "../../components/DataTable";

function Plugin() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [Progress, setProgress] = useState(0);
  const verRef = useRef("");
  const platformRef = useRef("");
  const bitRef = useRef("");
  const [data, setData] = useState([]);

  const columns = [
    { field: "fileName", headerName: "File Name", width: 400, align: "left" },
    {
      field: "uploadDate",
      headerName: "Uploaded Date",
      width: 300,
      align: "center",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      field: "version",
      headerName: "Version",
      minWidth: 130,
      align: "left",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      headerName: "Actions",
      minWidth: 170,
      align: "right",
      getActions: (params) => [

        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handlePluginDelete(params.row)}
        />,
      ]
    },
  ];

  const fetchPlugins = useCallback(async () => {
    let results = [];

    if (results.length === 0) {
      let q = query(collection(db, "Websites", "Client", "Plugins"));
      const snapshot = await getDocs(q);

      snapshot.forEach((snapData) => {
        results.push({ ...snapData.data(), id: snapData.id });
      });

      setData(results);
    }

  }, []);

  //HANDLE CRUD UPLOADS
  const handleUpload = (e) => {
    e.preventDefault();

    if (
      verRef.current.value !== "" &&
      platformRef.current.value !== "" &&
      bitRef.current.value !== ""
    ) {
      const FileInfo = e.target.files[0];

      const FileRef = ref(
        storage,
        `Plugins/${verRef.current?.value + FileInfo.name}`
      );

      const metaData = {
        contentType: FileInfo.type,
      };

      const UplodPlugin = uploadBytesResumable(FileRef, FileInfo, metaData);

      UplodPlugin.on(
        "state_changed",
        (snapshot) => {
          setIsPending(true);
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          setIsPending(false);
          setError(FilterText(error.message));
        },
        () => {
          getDownloadURL(UplodPlugin.snapshot.ref).then(async (downloadURL) => {
            //ADD NEW BUSY PLUGIN LINKS
            let docRef = collection(db, "Websites", "Client", "Plugins");

            await addDoc(docRef, {
              uploadDate: new Date().toLocaleDateString("en-IN", {
                timeZone: "Asia/Kolkata",
              }),
              fileName: FileInfo.name,
              version: verRef.current?.value,
              platform: platformRef.current?.value,
              bit: bitRef.current?.value,
              downloadURL: downloadURL,
            });
            setMessage("Uploaded successfully!!");
            fetchPlugins();
            document.getElementById("uploadForm").reset();
          });
        }
      );
    } else if (verRef.current.value === "") {
      setError("Version is missing (:");
    } else if (platformRef.current.value === "") {
      setError("Platform is missing (:");
    } else if (bitRef.current.value === "") {
      setError("Bit is missing (:");
    } else {
      setError("Something is wrong!!");
    }
  };

  const handlePluginDelete = async (data) => {

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `Plugins/${data.version + data.fileName}`);
      await deleteObject(storageRef);
    } catch (error) {
      setError(FilterText(error.message));
    }

    try {
      let deleteDocRef = doc(db, "Websites", "Client", "Plugins", data.id);
      await deleteDoc(deleteDocRef);
      fetchPlugins();
    } catch (error) {
      setError(FilterText(error.message));
    }
  };

  //BUSY INFO FETCH
  useEffect(() => {
    fetchPlugins();
  }, [fetchPlugins]);

  useEffect(() => {
    setTimeout(() => {
      setIsPending(false);
      setProgress(0);
      setMessage("");
      setError("");
      verRef.current.value = "";
      platformRef.current.value = "";
      bitRef.current.value = "";
    }, 5000);
  }, [message, error]);

  return (
    <>
      <div className="flex w-screen ">
        <Sidebar />
        <div className="body w-64 flex-1 bg-white p-8 h-screen overflow-y-auto">
          <div>
            <h1 className="bg-purple-400 p-2 text-2xl font-thin text-white">
              UPLOADS
            </h1>
            <div className="w-full p-2 bg-gray-300">
              <div className="m-4 flex flex-col items-center">
                <div className="rounded-md bg-white p-4 shadow-md">
                  <div className="flex flex-col space-y-2">
                    <p className="text-xl font-bold uppercase leading-8 tracking-wider text-gray-600">
                      UPLOAD
                    </p>
                    <form id="uploadForm" className="flex flex-col space-y-2 p-2">
                      <input
                        type="text"
                        name="platform"
                        id="platform"
                        className="rounded-xl border border-gray-600 p-2"
                        placeholder="Enter platform"
                        ref={platformRef}
                        required
                      />
                      <input
                        type="text"
                        name="version"
                        id="version"
                        className="rounded-xl border border-gray-600 p-2"
                        placeholder="Enter version"
                        ref={verRef}
                        required
                      />
                      <input
                        type="text"
                        name="bit"
                        id="bit"
                        className="rounded-xl border border-gray-600 p-2"
                        placeholder="Enter bit"
                        ref={bitRef}
                        required
                      />
                      <input
                        type="file"
                        name=""
                        id=""
                        disabled={isPending}
                        onChange={handleUpload}
                        required
                      />
                    </form>
                    <div className="">
                      <span className="mb-4 flex w-full items-center  justify-center space-x-2 rounded-xl bg-green-400 p-1">
                        <Upload color="white" />
                        {Math.floor(Progress)}%
                      </span>
                    </div>
                  </div>
                  <p className="md pt-4 font-bold text-red-600">{error}</p>
                </div>
              </div>
            </div>
            <div className="my-4">
              {message && <Alerts alertType="Info">{message}</Alerts>}
              {error && <Alerts alertType="Error">{error}</Alerts>}
            </div>
            <div className="my-4">
              <h1 className="bg-purple-400 p-2 text-2xl font-thin text-white">
                UPLOAD
              </h1>
              <div className="w-full bg-gray-300 p-2">
                <DataTable columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Plugin;
