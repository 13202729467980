import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import DataTable from "../../components/DataTable";
import {
  query,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  collection,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Edit } from "@mui/icons-material";
import FilterText from "../../utils/FilterText";
import { useRef } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';

export default function Products() {
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [formData, setFormData] = useState({
    productName: "",
    productValidity: 0,
    accessPlatform: "",
    description: "",
    gstCredits: 0,
    gstUsedCredits: 0,
    gstRemainingCredits: 0,
    noOfCompanies: 0,
    noOfUsedCompanies: 0,
    noOfRemainingCompanies: 0,
    braCredits: 0,
    braUsedCredits: 0,
    braRemainingCredits: 0,
    brmCredits: 0,
    brmUsedCredits: 0,
    brmRemainingCredits: 0,
    bpaCredits: 0,
    bpaUsedCredits: 0,
    bpaRemainingCredits: 0,
    noOfBanks: 0,
    noOfUsedBanks: 0,
    noOfRemainingBanks: 0,
    noOfAOSCompanies: 0,
    noOfUsedAOSCompanies: 0,
    noOfRemainingAOSCompanies: 0,
  });
  const [message, setMessage] = useState("");
  const originalDataRef = useRef([]);
  const [addFormData, setAddFormData] = useState({
    productName: "",
    productValidity: 15,
    accessPlatform: "",
    description: "",
    gstCredits: 0,
    gstUsedCredits: 0,
    gstRemainingCredits: 0,
    noOfCompanies: 0,
    noOfUsedCompanies: 0,
    noOfRemainingCompanies: 0,
    braCredits: 0,
    braUsedCredits: 0,
    braRemainingCredits: 0,
    brmCredits: 0,
    brmUsedCredits: 0,
    brmRemainingCredits: 0,
    bpaCredits: 0,
    bpaUsedCredits: 0,
    bpaRemainingCredits: 0,
    noOfBanks: 0,
    noOfUsedBanks: 0,
    noOfRemainingBanks: 0,
    noOfAOSCompanies: 0,
    noOfUsedAOSCompanies: 0,
    noOfRemainingAOSCompanies: 0,
  });

  const columns = [
    { field: "productName", headerName: "Product Name", minWidth: 70, align: "left" },
    { field: "productValidity", headerName: "Validity", minWidth: 70, align: "left" },
    { field: "accessPlatform", headerName: "Access Platform", minWidth: 170, align: "left" },
    {
      field: "description",
      headerName: "Description",
      minWidth: 130,
      align: "left",
    },
    {
      field: "gstCredits",
      headerName: "GST Credits",
      minWidth: 90,
      align: "right",
    },
    {
      field: "braCredits",
      headerName: "BRA Credits",
      minWidth: 90,
      align: "right",
    },
    {
      field: "brmCredits",
      headerName: "BRM Credits",
      minWidth: 90,
      align: "right",
    },
    {
      field: "bpaCredits",
      headerName: "BRP Credits",
      minWidth: 90,
      align: "right",
    },
    { field: "noOfCompanies", headerName: "N.O.C", minWidth: 70, align: "left" },
    {
      field: "noOfBanks",
      headerName: "N.O.B",
      minWidth: 90,
      align: "right",
    },
    {
      field: "noOfAOSCompanies",
      headerName: "A.O.S",
      minWidth: 90,
      align: "right",
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      headerName: "Actions",
      minWidth: 170,
      align: "right",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEdit(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDelete(params.row)}
        />,
      ]
    },
  ];

  //CRUD PRODUCTS START
  const fetchAllProducts = async () => {
    const q = query(collection(db, "Websites", "Client", "Products"), orderBy("productName", "asc"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    if (querySnapshotSize > 0) {
      snapshots.forEach((doc) => {
        //FORMATE DATE OF JOINING START

        if (doc.data()) {
          let {
            productName,
            productValidity,
            accessPlatform,
            description,
            gstCredits,
            gstUsedCredits,
            gstRemainingCredits,
            noOfCompanies,
            noOfUsedCompanies,
            noOfRemainingCompanies,
            braCredits,
            braUsedCredits,
            braRemainingCredits,
            brmCredits,
            brmUsedCredits,
            brmRemainingCredits,
            bpaCredits,
            bpaUsedCredits,
            bpaRemainingCredits,
            noOfBanks,
            noOfUsedBanks,
            noOfRemainingBanks,
            noOfAOSCompanies,
            noOfUsedAOSCompanies,
            noOfRemainingAOSCompanies,
          } = doc.data();

          records.push({
            id: doc.id,
            productName,
            productValidity,
            accessPlatform: accessPlatform instanceof Array ? accessPlatform?.join(",") : accessPlatform,
            description,
            gstCredits,
            gstUsedCredits,
            gstRemainingCredits,
            noOfCompanies,
            noOfUsedCompanies,
            noOfRemainingCompanies,
            braCredits,
            braUsedCredits,
            braRemainingCredits,
            brmCredits,
            brmUsedCredits,
            brmRemainingCredits,
            bpaCredits,
            bpaUsedCredits,
            bpaRemainingCredits,
            noOfBanks,
            noOfUsedBanks,
            noOfRemainingBanks,
            noOfAOSCompanies,
            noOfUsedAOSCompanies,
            noOfRemainingAOSCompanies,
          });
        }

        if (++i === querySnapshotSize) {
          setData(records);
          originalDataRef.current = records;
        }
      });
    } else {
      setData([]);
    }

  };

  const handleEdit = (data) => {
    setEditing(!editing);
    setFormData(data);
  };

  const handleChange = (e) => {
    if (e.target.name === "accessPlatform") {

      setFormData(prevData => {
        if (formData.accessPlatform?.length > 0) {
          if (!e.target.checked) {
            return { ...prevData, [e.target.name]: prevData.accessPlatform instanceof Array ? prevData.accessPlatform.filter(fd => fd !== e.target.value) : prevData.accessPlatform.split(",").filter(fd => fd !== e.target.value) }
          }
          return { ...prevData, [e.target.name]: formData.accessPlatform instanceof Array ? [...formData.accessPlatform, e.target.value] : [...formData.accessPlatform.split(","), e.target.value] }
        } else {
          return { ...prevData, [e.target.name]: [e.target.value] }
        }

      });
    } else {
      setFormData((prevData) => {
        return { ...prevData, [e.target.name]: e.target.value };
      });
    }
  };

  const handleAddChange = (e) => {

    if (e.target.name === "accessPlatform") {
      setAddFormData(prevData => {
        if (addFormData.accessPlatform?.length > 0) {
          if (!e.target.checked) {
            return { ...prevData, [e.target.name]: prevData.accessPlatform.filter(fd => fd !== e.target.value) }
          }
          return { ...prevData, [e.target.name]: [...addFormData.accessPlatform, e.target.value] }
        } else {
          return { ...prevData, [e.target.name]: [e.target.value] }
        }

      });
    } else {
      setAddFormData((prevData) => {
        return { ...prevData, [e.target.name]: e.target.value };
      });
    }
  };

  const handleShowCreate = async (e) => {
    e.preventDefault();
    setAdding(!adding);
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    try {
      let docRef = collection(db, "Websites", "Client", "Products");

      await addDoc(docRef, {
        ...addFormData,
        gstRemainingCredits: Number(addFormData.gstCredits),
        noOfRemainingCompanies: Number(addFormData.noOfCompanies),
        braRemainingCredits: Number(addFormData.braCredits),
        brmRemainingCredits: Number(addFormData.brmCredits),
        bpaRemainingCredits: Number(addFormData.bpaCredits),
        noOfRemainingBanks: Number(addFormData.noOfBanks),
        noOfRemainingAOSCompanies: Number(addFormData.noOfAOSCompanies),
        gstUsedCredits: 0,
        braUsedCredits: 0,
        brmUsedCredits: 0,
        bpaUsedCredits: 0,
        noOfUsedBanks: 0,
        noOfUsedCompanies: 0,
        noOfUsedAOSCompanies: 0,
      });

      setAdding(!adding);
      setAddFormData({});
      setMessage("");
      fetchAllProducts();
    } catch (err) {
      console.log(err);
      setMessage(err.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      let docRef = doc(db, "Websites", "Client", "Products", formData.id);

      await setDoc(docRef, {
        ...formData,
        gstRemainingCredits: formData.gstCredits,
        noOfRemainingCompanies: formData.noOfCompanies,
        braRemainingCredits: formData.braCredits,
        brmRemainingCredits: formData.brmCredits,
        bpaRemainingCredits: formData.bpaCredits,
        noOfRemainingBanks: formData.noOfBanks,
        noOfRemainingAOSCompanies: formData.noOfAOSCompanies,
        gstUsedCredits: 0,
        braUsedCredits: 0,
        brmUsedCredits: 0,
        bpaUsedCredits: 0,
        noOfUsedBanks: 0,
        noOfUsedCompanies: 0,
        noOfUsedAOSCompanies: 0,
      });

      setEditing(!editing);
      setMessage("");
      setFormData({});
      fetchAllProducts();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async ({ id }) => {
    try {
      let docRef = doc(db, "Websites", "Client", "Products", id);
      await deleteDoc(docRef);
      fetchAllProducts();
    } catch (error) {
      setMessage(FilterText(error.message));
    }
  };
  //CRUD PRODUCTS END

  useEffect(() => {
    fetchAllProducts();
  }, [adding, editing]);

  return (
    <div className="flex w-screen">
      <Sidebar />

      {adding && (
        <div className="body w-64 flex-1 bg-gray-200 p-8 overflow-y-scroll h-screen">
          <p className="mb-4 w-full text-center font-sans text-xl text-red-500">
            {FilterText(message)}
          </p>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={handleCreate}>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 md:col-span-3 sm:col-span-2">
                        <label
                          htmlFor="productName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Product Name
                        </label>
                        <input
                          required
                          type="text"
                          name="productName"
                          id="productName"
                          value={addFormData.productName}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-2">
                        <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">Access  Platform</h3>
                        <ul className="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                          <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                            <div className="flex items-center pl-3">
                              <input
                                name="accessPlatform"
                                id="desktopApp"
                                type="checkbox"
                                value="Desktop App"
                                onChange={handleAddChange}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label htmlFor="desktopApp" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Desktop App</label>
                            </div>
                          </li>
                          <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                            <div className="flex items-center pl-3">
                              <input
                                name="accessPlatform"
                                id="mobileApp"
                                type="checkbox"
                                value="Mobile App"
                                onChange={handleAddChange}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label htmlFor="mobileApp" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mobile App</label>
                            </div>
                          </li>
                          <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                            <div className="flex items-center pl-3">
                              <input
                                name="accessPlatform"
                                id="web"
                                type="checkbox"
                                value="Web"
                                onChange={handleAddChange}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label htmlFor="web" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Web</label>
                            </div>
                          </li>
                          <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                            <div className="flex items-center pl-3">
                              <input
                                name="accessPlatform"
                                id="tdl"
                                type="checkbox"
                                value="TDL"
                                onChange={handleAddChange}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label htmlFor="tdl" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">TDL</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <textarea
                          required
                          type="text"
                          name="description"
                          id="description"
                          value={addFormData.description}
                          onChange={handleAddChange}
                          cols="30"
                          rows="8">

                        </textarea>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="productValidity"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Product Validity
                        </label>
                        <input
                          required
                          type="number"
                          name="productValidity"
                          id="productValidity"
                          value={addFormData.productValidity}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="brmCredits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          BRM Credits
                        </label>
                        <input
                          required
                          type="number"
                          name="brmCredits"
                          id="brmCredits"
                          value={addFormData.brmCredits}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="braCredits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          BRA Credits
                        </label>
                        <input
                          required
                          type="number"
                          name="braCredits"
                          id="braCredits"
                          value={addFormData.braCredits}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="gstCredits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          GST Credits
                        </label>
                        <input
                          required
                          type="number"
                          name="gstCredits"
                          id="gstCredits"
                          value={addFormData.gstCredits}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="bpaCredits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          BPA Credits
                        </label>
                        <input
                          required
                          type="number"
                          name="bpaCredits"
                          id="bpaCredits"
                          value={addFormData.bpaCredits}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="noOfCompanies"
                          className="block text-sm font-medium text-gray-700"
                        >
                          No. of Companies
                        </label>
                        <input
                          required
                          type="number"
                          name="noOfCompanies"
                          id="noOfCompanies"
                          value={addFormData.noOfCompanies}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="noOfBanks"
                          className="block text-sm font-medium text-gray-700"
                        >
                          No. of Banks
                        </label>
                        <input
                          required
                          type="number"
                          name="noOfBanks"
                          id="noOfBanks"
                          value={addFormData.noOfBanks}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="noOfAOSCompanies"
                          className="block text-sm font-medium text-gray-700"
                        >
                          No. of A.O.S Companies
                        </label>
                        <input
                          required
                          type="number"
                          name="noOfAOSCompanies"
                          id="noOfAOSCompanies"
                          value={addFormData.noOfAOSCompanies}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      value="Submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Add
                    </button>
                    <button
                      type="submit"
                      value="Submit"
                      onClick={() => setAdding(false)}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {editing && (
        <div className="body w-64 flex-1 bg-gray-200 p-8 overflow-y-scroll h-screen">
          <p className="mb-4 w-full text-center font-sans text-xl text-red-500">
            {FilterText(message)}
          </p>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Products Information
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Update products for reseller or user
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={handleUpdate}>
                <input
                  required
                  type="hidden"
                  name="id"
                  id="id"
                  defaultValue={formData.id}
                  autoComplete="id"
                />
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 md:col-span-3 sm:col-span-2">
                        <label
                          htmlFor="order"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Product Name
                        </label>
                        <input
                          required
                          type="text"
                          name="productName"
                          id="productName"
                          value={formData.productName}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-2">
                        <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">Access  Platform</h3>
                        <ul className="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                          <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                            <div className="flex items-center pl-3">
                              <input
                                name="accessPlatform"
                                id="desktopApp"
                                type="checkbox"
                                value="Desktop App"
                                onChange={handleChange}
                                checked={formData.accessPlatform.includes("Desktop App")}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label htmlFor="desktopApp" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Desktop App</label>
                            </div>
                          </li>
                          <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                            <div className="flex items-center pl-3">
                              <input
                                name="accessPlatform"
                                id="mobileApp"
                                type="checkbox"
                                value="Mobile App"
                                onChange={handleChange}
                                checked={formData.accessPlatform.includes("Mobile App")}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label htmlFor="mobileApp" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mobile App</label>
                            </div>
                          </li>
                          <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                            <div className="flex items-center pl-3">
                              <input
                                name="accessPlatform"
                                id="web"
                                type="checkbox"
                                value="Web"
                                onChange={handleChange}
                                checked={formData.accessPlatform.includes("Web")}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label htmlFor="web" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Web</label>
                            </div>
                          </li>
                          <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                            <div className="flex items-center pl-3">
                              <input
                                name="accessPlatform"
                                id="tdl"
                                type="checkbox"
                                value="TDL"
                                onChange={handleChange}
                                checked={formData.accessPlatform.includes("TDL")}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label htmlFor="tdl" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">TDL</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-3">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <textarea
                          required
                          type="text"
                          name="description"
                          id="description"
                          value={formData.description}
                          onChange={handleAddChange}
                          cols="30"
                          rows="3">

                        </textarea>
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-3">
                        <label
                          htmlFor="productValidity"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Product Validity
                        </label>
                        <input
                          required
                          type="number"
                          name="productValidity"
                          id="productValidity"
                          value={formData.productValidity}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-3">
                        <label
                          htmlFor="brmCredits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          BRM Credits
                        </label>
                        <input
                          required
                          type="number"
                          name="brmCredits"
                          id="brmCredits"
                          value={formData.brmCredits}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-3">
                        <label
                          htmlFor="braCredits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          BRA Credits
                        </label>
                        <input
                          required
                          type="number"
                          name="braCredits"
                          id="braCredits"
                          value={formData.braCredits}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-3">
                        <label
                          htmlFor="gstCredits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          GST Credits
                        </label>
                        <input
                          required
                          type="number"
                          name="gstCredits"
                          id="gstCredits"
                          value={formData.gstCredits}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-3">
                        <label
                          htmlFor="bpaCredits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          BPA Credits
                        </label>
                        <input
                          required
                          type="number"
                          name="bpaCredits"
                          id="bpaCredits"
                          value={formData.bpaCredits}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-3">
                        <label
                          htmlFor="noOfCompanies"
                          className="block text-sm font-medium text-gray-700"
                        >
                          No. of Companies
                        </label>
                        <input
                          required
                          type="number"
                          name="noOfCompanies"
                          id="noOfCompanies"
                          value={formData.noOfCompanies}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-3">
                        <label
                          htmlFor="noOfBanks"
                          className="block text-sm font-medium text-gray-700"
                        >
                          No. of Banks
                        </label>
                        <input
                          required
                          type="number"
                          name="noOfBanks"
                          id="noOfBanks"
                          value={formData.noOfBanks}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 md:col-span-3 sm:col-span-3">
                        <label
                          htmlFor="noOfAOSCompanies"
                          className="block text-sm font-medium text-gray-700"
                        >
                          No. of A.O.S Companies
                        </label>
                        <input
                          required
                          type="number"
                          name="noOfAOSCompanies"
                          id="noOfAOSCompanies"
                          value={formData.noOfAOSCompanies}
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      value="Submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Update
                    </button>
                    <button
                      type="submit"
                      value="Submit"
                      onClick={() => setEditing(false)}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {data && !editing && !adding && (
        <div className="body w-64 flex-1 bg-white p-8 ">
          <h1 className="mb-4 rounded bg-purple-400 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
            PRODUCTS
          </h1>
          {
            !adding &&
            !editing && (
              <div className="flex justify-end">
                <button className="bg-blue-400 text-white px-2 py-3 rounded-full" onClick={handleShowCreate}>
                  ADD
                </button>
              </div>
            )
          }
          <DataTable columns={columns} data={data} />
        </div>
      )}
    </div>
  );
}
