import React, { useState, useEffect, useContext } from "react";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

import Sidebar from "../../components/Sidebar";
import profile from "./profile.png";

import { useLocation } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";

import moment from "moment";

import "../../index.css";
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import FilterText from "../../utils/FilterText";
import { Edit } from "@mui/icons-material";


function Profile() {
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [message, setMessage] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [userPlanProducts, setUserPlanProducts] = useState(null);
  const [channelPartnerProfile, setChannelPartnerProfile] = useState(null);
  const [loading, setLoading] = useState([]);
  const location = useLocation();
  const { user } = useContext(AuthContext);

  let uid = location?.state?.id ? location?.state?.id : user.uid;

  const columns = [
    { field: "productName", headerName: "Product Name", minWidth: 170, align: "left" },
    {
      field: "startingFrom",
      headerName: "Joining Date",
      minWidth: 130,
      align: "left",
    },
    {
      field: "endingTo",
      headerName: "Expiry Date",
      minWidth: 130,
      align: "left",
      format: (value) => {
        moment(new Date(Number(value)))
          .utc()
          .format("DD-MM-YYYY");
      },
    },

    { field: "noOfCompanies", headerName: "No Of Companies", minWidth: 170, align: "left" },
    { field: "noOfUsedCompanies", headerName: "No Of Used Companies", minWidth: 170, align: "left" },
    // { field: "noOfRemainingCompanies", headerName: "N.O.R.C", minWidth: 170, align: "left" },
    { field: "noOfBanks", headerName: "No Of Banks", minWidth: 170, align: "left" },
    { field: "noOfUsedBanks", headerName: "No Of Used Banks", minWidth: 170, align: "left" },
    // { field: "noOfRemainingBanks", headerName: "N.O.R.B", minWidth: 170, align: "left" },
    { field: "gstCredits", headerName: "GST Credits", minWidth: 170, align: "left" },
    { field: "gstUsedCredits", headerName: "GST Used Credits", minWidth: 170, align: "left" },
    // { field: "gstRemainingCredits", headerName: "G.R Credits", minWidth: 170, align: "left" },
    { field: "bpaCredits", headerName: "BPA Credits", minWidth: 170, align: "left" },
    { field: "bpaUsedCredits", headerName: "BPA Used Credits", minWidth: 220, align: "left" },
    // { field: "bpaRemainingCredits", headerName: "B.P.A.R Credits", minWidth: 170, align: "left" },
    { field: "braCredits", headerName: "BRDA Credits", minWidth: 230, align: "left" },
    { field: "braUsedCredits", headerName: "BRDA Used Credits", minWidth: 280, align: "left" },
    // { field: "braRemainingCredits", headerName: "B.R.A.R Credits", minWidth: 170, align: "left" },
    { field: "brmCredits", headerName: "BRDM Credits", minWidth: 280, align: "left" },
    { field: "brmUsedCredits", headerName: "BRDM Used Credits", minWidth: 280, align: "left" },
    { field: "noOfAOSCompanies", headerName: "No of AOS Companies", minWidth: 280, align: "left" },
    { field: "noOfRemainingAOSCompanies", headerName: "No of Remaining AOS Companies", minWidth: 280, align: "left" },
    // { field: "brmRemainingCredits", headerName: "B.R.M.R Credits", minWidth: 170, align: "left" },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      headerName: "Actions",
      minWidth: 170,
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEdit(params.row)}
        />,
      ]
    },

  ];

  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    expiredate: 30,
    totalmessages: 30,
    plantype: "Demo",
    noOfAOSCompanies: 0,
    noOfRemainingAOSCompanies: 0
  });

  const formatDate = (data) => {
    if (data) {
      data = data.replace(/ +(?= )/g, '').replaceAll("/", "-").trim();
      let year = data.split("-")[2];
      let month = data.split("-")[1];
      let date = data.split("-")[0];

      return new Date(`${year}-${month}-${date}`).getTime();
    }
  }

  const handleChange = (e) => {
    setFormData((prevData) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  };

  const handleEdit = async (e) => {
    setFormData(e);
    setEditing(!editing);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    let userPlanProductsFilterData = userProfile?.productBenefits.filter(uf => uf.id !== formData.id);

    let newFormData = {
      ...formData,
      startingFrom: formatDate(formData.startingFrom),
      endingTo: formatDate(formData.endingTo)
    }

    userPlanProductsFilterData.push(newFormData);

    try {
      if (uid) {

        let userProfileData = {};
        let docRef = doc(db, "Users", uid);

        userProfileData = await getDoc(docRef);

        if (userProfileData.exists()) {
          await updateDoc(docRef, {
            ...userProfile,
            productBenefits: userPlanProductsFilterData
          })
        }
      }

      setEditing(!editing);
      setMessage("");
      setFormData({});
      setUserProfile({});
      fetchProfile();
    } catch (err) {
      console.log(err);
    }
  };

  let fetchProfile = async () => {
    setLoading(true);

    if (uid) {

      let userProfileData = {};
      let docRef = doc(db, "Users", uid);

      userProfileData = await getDoc(docRef);

      if (userProfileData.exists()) {
        setUserProfile(userProfileData.data());

        let userPlanProducts = userProfileData.data().productBenefits.map(pp => {
          return {
            ...pp,
            startingFrom: moment(new Date(Number(pp.startingFrom)))
              .utc()
              .format("DD-MM-YYYY"),
            endingTo: moment(new Date(Number(pp.endingTo)))
              .utc()
              .format("DD-MM-YYYY"),
            status: pp.endingTo
          }
        })

        setUserPlanProducts(userPlanProducts);
        setLoading(false);
      }
    } else {
      setLoading(false);

    }
  }

  let fetchResellerProfile = async () => {

    if (
      userProfile &&
      userProfile.channelPartnerRefferalId
    ) {

      let channelPartnerProfile = {};
      let docRef = doc(db, "Channel Partners", userProfile.channelPartnerRefferalId);

      channelPartnerProfile = await getDoc(docRef);

      if (channelPartnerProfile.exists()) {
        setChannelPartnerProfile(channelPartnerProfile.data());
        // console.log("channelPartnerProfile.data()", channelPartnerProfile.data());
      }
    }
  }

  useEffect(() => {
    if (uid) fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  useEffect(() => {

    if (userProfile) fetchResellerProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setSuccessMessage("");
    }, 1000);
  }, [error, successMessage]);

  return (
    <div className="flex w-screen ">
      <Sidebar />
      {!loading && userProfile && (
        <div className="body w-64 flex-1 px-4 bg-white  overflow-y-scroll h-screen" >
          <h1 className="mb-8 text-left text-4xl font-thin uppercase leading-10 text-black">

          </h1>
          <div className="flex">
            <div className="flex-1">
              <div className="items-left mb-8 flex flex-col justify-center space-y-1">
                <img className="mb-8 w-24 rounded-full" src={profile} alt="" />
                <div>
                  <span className="text-xs  uppercase text-[#055592] font-serif">
                    Name:
                  </span>
                  <span className="ml-2 text-black">
                    {userProfile.personalName}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <h1 className="text-xs uppercase text-[#055592] font-serif">
                ADDRESS
              </h1>
              <p className="text-xs font-thin uppercase text-black">
                {userProfile.city}
              </p>
              <p className="text-xs font-thin uppercase text-black">
                {userProfile.mobile}
              </p>
              <p className="text-xs font-thin text-black">
                {userProfile.email}
              </p>
              <p className="text-xs font-thin uppercase text-black">
                {userProfile.pinCode}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <fieldset className=" border  p-3 " style={{ color: "#055592" }}>
              <p className="font-serif uppercase border-b-2 border-b-blue-200 mb-2">User Credential</p>
              <div className="mb-4">
                <p className="text-xs font-thin uppercase text-[#055592]">
                  User ID:
                  <span className="ml-2 normal-case text-black">
                    {userProfile.uid}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Username:
                  <span className="ml-2 lowercase text-black">
                    {userProfile.email}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Password:
                  <span className=" ml-2 normal-case text-black">
                    {userProfile.password}
                  </span>
                </p>
              </div>
            </fieldset>
            <fieldset className=" border  p-3 " style={{ color: "#055592" }}>
              <p className="font-serif uppercase border-b-2 border-b-blue-200 mb-2">Plan</p>

              <div className="mb-4">
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Current Plan:
                  <span className="ml-2 normal-case text-black">
                    {userProfile?.licenceInfo?.plan}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  D.O.J:
                  <span className="ml-2 lowercase text-black">
                    {moment(userProfile?.activationDate.toDate().toISOString()).format('DD/MM/YYYY')}

                  </span>
                </p>

                <p className="text-xs font-thin uppercase text-[#055592]">
                  Features:
                  <span className=" ml-2 normal-case text-black">
                    {userProfile?.licenceInfo?.benefits}
                  </span>
                </p>
              </div>
            </fieldset>
            <fieldset className=" border  p-3 " style={{ color: "#055592" }}>
              <p className="font-serif uppercase border-b-2 border-b-blue-200 mb-2">Business Details</p>
              <div className="mb-4">
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Business Name:
                  <span className="ml-2 normal-case text-black">
                    {userProfile?.businessName}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Business Category:
                  <span className="ml-2 lowercase text-black">
                    {userProfile?.businessCategory}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Email:
                  <span className=" ml-2 normal-case text-black">
                    {userProfile?.email}
                  </span>
                </p>
              </div>
            </fieldset>
            <fieldset className=" border  p-3 " style={{ color: "#055592" }}>
              <p className="font-serif uppercase border-b-2 border-b-blue-200 mb-2">Your Channel Partner</p>
              <div className="mb-4">
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Name:
                  <span className="ml-2 normal-case text-black">
                    {channelPartnerProfile?.personalName}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Mobile:
                  <span className="ml-2 lowercase text-black">
                    {channelPartnerProfile?.mobile}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Email:
                  <span className=" ml-2 normal-case text-black">
                    {channelPartnerProfile?.email}
                  </span>
                </p>
              </div>
            </fieldset>
          </div>

          <p className="mb-4 text-center text-xs font-bold text-red-600">
            {error && error !== ""
              ? FilterText(error)
              : successMessage && successMessage !== ""
                ? FilterText(successMessage)
                : ""}
          </p>
          {
            !editing && (
              <div>
                <h1 className="text-2xl font-semibold text-[#055592] my-8 text-center">PLAN STATUS</h1>

                <Box
                  sx={{
                    height: 500,
                    width: '100%',
                    '& .active': {
                      // backgroundColor: '#007500',
                      color: '#007500',
                    },
                    '& .expire': {
                      // backgroundColor: '#e5020c',
                      color: '#e5020c',
                    },
                  }}
                >
                  <DataGrid
                    rows={userPlanProducts}
                    columns={columns}
                    getRowClassName={(params) => {

                      if (new Date().getTime() >= params.row.status) {
                        return 'expire';
                      }
                      else if (new Date().getTime() <= params.row.status) {
                        return 'active';
                      } else {
                        return '';
                      }
                    }}
                  />
                </Box>
              </div>
            )}
          {editing && (
            <div className="body flex-1 bg-gray-200 p-8">
              <p className="mb-4 w-full text-center font-sans text-xl text-red-500">
                {FilterText(message)}
              </p>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Plans Information
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Update plans for User
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <form onSubmit={handleUpdate}>
                    <input
                      required
                      type="hidden"
                      name="id"
                      id="id"
                      defaultValue={formData.id}
                      autoComplete="id"
                    />
                    <div className="overflow-hidden shadow sm:rounded-md">
                      <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="startingFrom"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Starting From
                            </label>
                            <input
                              required
                              type="date-local"
                              name="startingFrom"
                              id="startingFrom"
                              value={formData.startingFrom}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="endingTo"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Ending To
                            </label>
                            <input
                              required
                              type="date-local"
                              name="endingTo"
                              id="endingTo"
                              value={formData.endingTo}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="braCredits"
                              className="block text-sm font-medium text-gray-700"
                            >
                              BRA Credits
                            </label>
                            <input
                              required
                              type="number"
                              name="braCredits"
                              id="braCredits"
                              value={formData.braCredits}
                              onChange={handleChange}
                              readOnly
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="braRemainingCredits"
                              className="block text-sm font-medium text-gray-700"
                            >
                              BRA Remaining Credits
                            </label>
                            <input
                              required
                              type="number"
                              name="braRemainingCredits"
                              id="braRemainingCredits"
                              value={formData.braRemainingCredits}
                              onChange={handleChange}
                              readOnly
                              className="mt-1 w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="brmCredits"
                              className="block text-sm font-medium text-gray-700"
                            >
                              BRM Credits
                            </label>
                            <input
                              required
                              type="number"
                              name="brmCredits"
                              id="brmCredits"
                              value={formData.brmCredits}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="brmRemainingCredits"
                              className="block text-sm font-medium text-gray-700"
                            >
                              BRM Remaining Credits
                            </label>
                            <input
                              required
                              type="number"
                              name="brmRemainingCredits"
                              id="brmRemainingCredits"
                              value={formData.brmRemainingCredits}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="gstCredits"
                              className="block text-sm font-medium text-gray-700"
                            >
                              GST Credits
                            </label>
                            <input
                              required
                              type="number"
                              name="gstCredits"
                              id="gstCredits"
                              value={formData.gstCredits}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="gstRemainingCredits"
                              className="block text-sm font-medium text-gray-700"
                            >
                              GST Remaining Credits
                            </label>
                            <input
                              required
                              type="number"
                              name="gstRemainingCredits"
                              id="gstRemainingCredits"
                              value={formData.gstRemainingCredits}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="noOfAOSCompanies"
                              className="block text-sm font-medium text-gray-700"
                            >
                              No. Of AOS Companies
                            </label>
                            <input
                              required
                              type="number"
                              name="noOfAOSCompanies"
                              id="noOfAOSCompanies"
                              value={formData.noOfAOSCompanies}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="noOfRemainingAOSCompanies"
                              className="block text-sm font-medium text-gray-700"
                            >
                              No. Of AOS Remaining Companies
                            </label>
                            <input
                              required
                              type="number"
                              name="noOfRemainingAOSCompanies"
                              id="noOfRemainingAOSCompanies"
                              value={formData.noOfRemainingAOSCompanies}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="noOfCompanies"
                              className="block text-sm font-medium text-gray-700"
                            >
                              No. Of Companies
                            </label>
                            <input
                              required
                              type="number"
                              name="noOfCompanies"
                              id="noOfCompanies"
                              value={formData.noOfCompanies}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="noOfRemainingCompanies"
                              className="block text-sm font-medium text-gray-700"
                            >
                              No Of Remaining Companies
                            </label>
                            <input
                              required
                              type="number"
                              name="noOfRemainingCompanies"
                              id="noOfRemainingCompanies"
                              value={formData.noOfRemainingCompanies}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="bpaCredits"
                              className="block text-sm font-medium text-gray-700"
                            >
                              No Of Remaining Companies
                            </label>
                            <input
                              required
                              type="number"
                              name="bpaCredits"
                              id="bpaCredits"
                              value={formData.bpaCredits}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="bpaRemainingCredits"
                              className="block text-sm font-medium text-gray-700"
                            >
                              No Of Remaining Companies
                            </label>
                            <input
                              required
                              type="number"
                              name="bpaRemainingCredits"
                              id="bpaRemainingCredits"
                              value={formData.bpaRemainingCredits}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="noOfBanks"
                              className="block text-sm font-medium text-gray-700"
                            >
                              No Of Banks
                            </label>
                            <input
                              required
                              type="number"
                              name="noOfBanks"
                              id="noOfBanks"
                              value={formData.noOfBanks}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="noOfRemainingBanks"
                              className="block text-sm font-medium text-gray-700"
                            >
                              No Of Remaining Banks
                            </label>
                            <input
                              required
                              type="number"
                              name="noOfRemainingBanks"
                              id="noOfRemainingBanks"
                              value={formData.noOfRemainingBanks}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="productValidity"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Product Validity
                            </label>
                            <input
                              required
                              type="number"
                              name="productValidity"
                              id="productValidity"
                              value={formData.productValidity}
                              onChange={handleChange}
                              className=" mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                          type="submit"
                          value="Submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Update
                        </button>
                        <button
                          type="submit"
                          value="Submit"
                          onClick={() => setEditing(false)}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

        </div>
      )}
    </div>
  );
}

export default Profile;
