import { useContext, useRef, useState } from "react";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Alerts from "../../components/Alerts";
import FilterText from "../../utils/FilterText";

const Login = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visibility, setVisibility] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const [isPending, setIsPending] = useState(false);

  const navigate = useNavigate();

  const { dispatch } = useContext(AuthContext);

  const handleEmailChange = (e) => {
    e.preventDefault();
    emailRef.current = e.target.value;
  };

  const handleVisibility = () => {
    setVisibility(!visibility);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    passwordRef.current = e.target.value;
  };

  const toTitleCase = (str) => {
    return str.replaceAll("_", " ").replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setIsPending(true);
    let email = emailRef.current;
    let password = passwordRef.current;

    if (email.trim() === "superadmin@tallykonnect.com") {
      console.log(auth, email, password);

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {

          // Signed in
          const user = userCredential.user;

          if (user && Object.entries(user).length > 0) {
            setIsPending(false);
            dispatch({ type: "LOGIN", payload: user });
            localStorage.setItem("user", JSON.stringify(user));
            navigate("/dashboard");
          }

        })
        .catch((error) => {
          setIsPending(false);
          console.log("error", error);
          setError(FilterText(error.message));
        });
    } else {
      setIsPending(false);
      localStorage.removeItem("user");
      setError("Not a valid Super Admin :(");
    }
  };

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-12 px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-24 w-auto" src="https://tallykonnect.com/static/media/logo.a8ed201928e329d7fc48.png" alt="Tally Konnect" />
        <h2 className="mt-6 text-center text-3xl font-extrabold" style={{ color: "#055592" }}>
          SIGN IN
        </h2>

      </div>

      <div className="mt-4 sm:mx-auto sm:w-[400px]">
        <div className="rounded-lg bg-white py-4 px-3 shadow sm:px-10">
          <form className="mb-0 space-y-6" onSubmit={handleLogin}>
            <div>
              <label htmlFor="email">Email</label>
              <div className="mt-1 relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="w-6 h-6 absolute top-2 left-1" style={{ color: "#055592" }}>
                  <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                </svg>

                <input
                  className="pl-8"
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  ref={emailRef}
                  onChange={handleEmailChange}
                  required
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="relative mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="w-6 h-6 absolute top-2 left-1" style={{ color: "#055592" }}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>

                <input
                  className="pl-8"
                  id="password"
                  name="password"
                  type={visibility ? "text" : "password"}
                  ref={passwordRef}
                  onChange={handlePasswordChange}
                  required
                />
                {visibility ? (
                  <VisibilityOffIcon
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={() => handleVisibility()}
                  />
                ) : (
                  <VisibilityIcon
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={() => handleVisibility()}
                  />
                )}
              </div>
            </div>

            <div className="flex items-center">
              <div className="flex items-center">
                <input
                  id="terms-and-privacy"
                  name="terms-and-privacy"
                  type="checkbox"
                />
                <label
                  htmlFor="terms-and-privacy"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>
              <div>
                <Link
                  to="/resetpassword"
                  className="hover:text-blue-400focus:outline-none ml-2 font-serif text-sm text-blue-400 focus:ring-2 focus:ring-blue-500"
                >
                  Forgot Password?
                </Link>
              </div>
            </div>
            <div className="flex w-full flex-col items-center space-y-2">
              {!isPending && (
                <button
                  type="submit"
                  className="w-[150px] rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" style={{ backgroundColor: "#055592" }}
                >
                  Log In
                </button>
              )}
              {isPending && (
                <button
                  type="submit"
                  className="w-[150px] rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" style={{ backgroundColor: "#055592" }}
                  disabled
                >
                  Wait....
                </button>
              )}
              {error && <Alerts alertType="Error">{error}</Alerts>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
