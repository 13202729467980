const { initializeApp } = require("firebase/app");
const { getAuth } = require("firebase/auth");
const { getFirestore } = require("firebase/firestore");
const { getStorage } = require("firebase/storage");

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "tally-konnect-55ffb.firebaseapp.com",
  databaseURL: "https://tally-konnect-55ffb-default-rtdb.firebaseio.com",
  projectId: "tally-konnect-55ffb",
  storageBucket: "tally-konnect-55ffb.appspot.com",
  messagingSenderId: "93429636162",
  appId: "1:93429636162:web:5bcd680539d281a6fa61db",
  measurementId: "G-R3TKNMSTBB"
};

const app = initializeApp(firebaseConfig);
module.exports.db = getFirestore(app);
module.exports.auth = getAuth();
module.exports.storage = getStorage(app);
