import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Login from "./pages/login/Login";

import Users from "./pages/users/Users";
import Plans from "./pages/plans/Plans";
import Products from "./pages/product/Products";
import Plugin from "./pages/plugins/Plugin";
import UserProfile from "./pages/userprofile/UserProfile";

import Order from "./pages/order/Order";
import Dashboard from "./pages/dashboard/Dashboard";
import ContactUs from "./pages/contactus/ContactUs";
import Demo from "./pages/demo/Demo";
import ChannelPartner from "./pages/channelpartner/ChannelPartner";
import ChannelPartnerProfile from "./pages/channelpartner/ChannelPartnerProfile";
import Broadcast from "./pages/broadcast/Broadcast";
import Testimonials from "./pages/testimonials/Testimonials";
import FollowUpDemo from "./pages/demo/FollowUpDemo";
import ContactUsFollowUp from "./pages/contactus/ContactUsFollowUp";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import CouponCode from "./pages/coupons/CouponCode";

function App() {
  const { user } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return user ? children : <Navigate to="/login" />;
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route
              index
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="plans"
              element={
                <RequireAuth>
                  <Plans />
                </RequireAuth>
              }
            />
            <Route
              path="products"
              element={
                <RequireAuth>
                  <Products />
                </RequireAuth>
              }
            />
            <Route
              path="contactus"
              element={
                <RequireAuth>
                  <ContactUs />
                </RequireAuth>
              }
            />
            <Route
              path="contactUsFollowUpDetails"
              element={
                <RequireAuth>
                  <ContactUsFollowUp />
                </RequireAuth>
              }
            />
            <Route
              path="demo"
              element={
                <RequireAuth>
                  <Demo />
                </RequireAuth>
              }
            />
            <Route
              path="followupDemoDetails"
              element={
                <RequireAuth>
                  <FollowUpDemo />
                </RequireAuth>
              }
            />
            <Route path="users">
              <Route
                index
                element={
                  <RequireAuth>
                    <Users />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="channelpartner">
              <Route
                index
                element={
                  <RequireAuth>
                    <ChannelPartner />
                  </RequireAuth>
                }
              />
            </Route>

            <Route
              path="plugin"
              element={
                <RequireAuth>
                  <Plugin />
                </RequireAuth>
              }
            />
            <Route path="userprofile">
              <Route
                index
                element={
                  <RequireAuth>
                    <UserProfile />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="channelpartnerprofile">
              <Route
                index
                element={
                  <RequireAuth>
                    <ChannelPartnerProfile />
                  </RequireAuth>
                }
              />
            </Route>
          </Route>
          <Route
            path="order"
            element={
              <RequireAuth>
                <Order />
              </RequireAuth>
            }
          />
          <Route
            path="couponCode"
            element={
              <RequireAuth>
                <CouponCode />
              </RequireAuth>
            }
          />
          <Route
            path="broadcast"
            element={
              <RequireAuth>
                <Broadcast />
              </RequireAuth>
            }
          />
          <Route path="testimonials" element={
            <RequireAuth>
              <Testimonials />
            </RequireAuth>
          } />
          <Route path="resetpassword" element={

            <ResetPassword />

          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
