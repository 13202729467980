import { Edit } from '@mui/icons-material';
import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, serverTimestamp, setDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Sidebar from '../../components/Sidebar'
import { db } from '../../firebase';
import FilterText from '../../utils/FilterText';
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from "../../components/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid";

function CouponCode() {

    const [data, setData] = useState([]);
    const [editing, setEditing] = useState(false);
    const [adding, setAdding] = useState(false);
    const [addFormData, setAddFormData] = useState({
        couponCode: "",
        note: "",
        from: "",
        to: "",
        noOfTimesUsed: 0,
        noOfTimesAllowed: 0,
        status: "Active",
    });
    const [formData, setFormData] = useState({
        couponCode: "",
        note: "",
        from: "",
        to: "",
        noOfTimesUsed: 0,
        noOfTimesAllowed: 0,
        status: "",
    });

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const columns = [

        {
            field: "createdAt",
            headerName: "Date",
            minWidth: 130,
            align: "left",
            format: (value) => value.toLocaleString("en-IN"),
        },
        { field: "couponCode", headerName: "Coupon Code", minWidth: 170, align: "left" },
        {
            field: "from",
            headerName: "From",
            minWidth: 170,
            align: "left",
            format: (value) => value.toLocaleString("en-IN"),
        },
        {
            field: "to",
            headerName: "To",
            minWidth: 170,
            align: "left",
            format: (value) => value.toLocaleString("en-IN"),
        },
        { field: "note", headerName: "Note", minWidth: 170, align: "left" },
        { field: "noOfTimesUsed", headerName: "No Of Times Used", minWidth: 170, align: "left" },
        { field: "noOfTimesAllowed", headerName: "No Of Times Allowed", minWidth: 170, align: "left" },
        { field: "status", headerName: "Status", minWidth: 170, align: "left" },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="Edit"
                    onClick={() => handleShowEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDelete(params.row)}
                />,
            ]
        },
    ];

    const handleAddChange = (e) => {
        setAddFormData((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const handleUpdateChange = (e) => {
        setFormData((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const handleCancelAdd = (e) => {
        e.preventDefault();
        setAdding(!adding);
    }

    const handleCancelUpdate = (e) => {
        e.preventDefault();
        setEditing(!editing);
    }

    const handleShowCreate = async (e) => {
        e.preventDefault();
        setAdding(!adding);
    };

    const handleShowEdit = (data) => {
        setEditing(!editing);
        setFormData(data);
    };


    //HANDLE CRUD UPLOADS

    const fetchAllCouponCode = async () => {
        const q = query(collection(db, "Websites", "Admin", "CouponCode"), orderBy("createdAt", "asc"));
        const snapshots = await getDocs(q);

        let records = [];
        let querySnapshotSize = snapshots.docs.length;
        let i = 0;

        if (querySnapshotSize > 0) {
            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                if (doc.data()) {
                    let {
                        couponCode,
                        note,
                        from,
                        to,
                        noOfTimesAllowed,
                        noOfTimesUsed,
                        status,
                        createdAt
                    } = doc.data();

                    records.push({
                        id: doc.id,
                        couponCode,
                        note,
                        from,
                        to,
                        noOfTimesAllowed,
                        noOfTimesUsed,
                        status,
                        createdAt: createdAt.toDate(),
                    });

                }

                if (++i === querySnapshotSize) {
                    setData(records);
                }
            });
        } else {
            setData([]);
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();

        try {
            let docRef = collection(db, "Websites", "Admin", "CouponCode");
            await addDoc(docRef, {
                ...addFormData,
                createdAt: serverTimestamp()
            });

            setAddFormData({
                couponCode: "",
                note: "",
                from: "",
                to: "",
                noOfTimesAllowed: 0,
                noOfTimesUsed: 0,
                status: ""
            });
            setMessage("CouponCode message is added !!");
            document.getElementById("couponCodeForm").reset();
            fetchAllCouponCode();
        } catch (err) {
            console.log(err);
            setError(err.message);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        try {
            let docRef = doc(db, "Websites", "Admin", "CouponCode", formData.id);
            await setDoc(docRef, { ...formData, updatedAt: serverTimestamp() });

            setEditing(!editing);
            setMessage("");
            setFormData({
                couponCode: "",
                note: "",
                from: "",
                to: "",
                noOfTimesAllowed: 0,
                noOfTimesUsed: 0,
                status: ""
            });
            fetchAllCouponCode();
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = async ({ id }) => {
        try {
            let docRef = doc(db, "Websites", "Admin", "CouponCode", id);
            await deleteDoc(docRef);
            fetchAllCouponCode();
        } catch (error) {
            setError(FilterText(error.message));
        }
    };

    //CRUD PLANS END

    //STEP II
    useEffect(() => {
        setTimeout(() => {
            setMessage("");
            setError("");
        }, 5000);
    }, [message, error]);

    //STEP I
    useEffect(() => {
        fetchAllCouponCode();
    }, [])

    return (
        <div className="flex">
            <Sidebar />
            <div className='w-full h-screen overflow-y-scroll'>
                <h1 className="mb-4 rounded bg-purple-400 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl m-auto w-[50%] mt-12">
                    COUPON CODE
                </h1>
                <div className='w-full p-8'>

                    {
                        !adding &&
                        !editing && (
                            <div className="flex justify-end">
                                <button className="bg-purple-400 text-white px-2 py-3 rounded-full" onClick={handleShowCreate}>
                                    ADD
                                </button>
                            </div>
                        )
                    }
                    <div className='flex flex-col items-center my-4 w-full mx-auto '>
                        {message && <p className='text-center text-white bg-green-400 px-4 py-1'>{message}</p>}
                        {error && <p className='text-center text-white bg-red-400 px-4 py-1'>{error}</p>}
                        {
                            adding && (<div className=" bg-white rounded-lg shadow-2xl p-6 w-[50%]">
                                <form onSubmit={handleCreate} id="couponCodeForm">
                                    <div className='my-2'>
                                        <label className="block text-md font-medium text-gray-700 " htmlFor="note">Note</label>
                                        <textarea className='p-1 w-full border border-gray-300' name="note" id="note" cols="30" rows="5" value={addFormData.note} onChange={handleAddChange}></textarea>
                                    </div>
                                    <div className='my-2'>
                                        <label className="block text-md font-medium text-gray-700 " htmlFor="couponCode">Coupon Code</label>
                                        <input type="text" id="couponCode" name='couponCode' value={addFormData.couponCode} onChange={handleAddChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </div>
                                    <div className='my-2'>
                                        <label className="block text-md font-medium text-gray-700 " htmlFor="from">From</label>
                                        <input type="datetime-local" id="from" name='from' value={addFormData.from} onChange={handleAddChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </div>
                                    <div className='my-2'>
                                        <label className="block text-md font-medium text-gray-700 " htmlFor="to">To</label>
                                        <input type="datetime-local" id="to" name='to' value={addFormData.to} onChange={handleAddChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </div>
                                    <div className='my-2'>
                                        <label className="block text-md font-medium text-gray-700 " htmlFor="noOfTimesAllowed">No Of Times Allowed</label>
                                        <input type="number" id="noOfTimesAllowed" name='noOfTimesAllowed' value={addFormData.noOfTimesAllowed} defaultValue="1" onChange={handleAddChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </div>

                                    <div className='my-4 text-right'>
                                        <button type='submit' className='bg-blue-500 text-white mr-2 px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300'>Create</button>
                                        <button className='bg-red-500 text-white px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300' onClick={handleCancelAdd}>Cancel</button>
                                    </div>
                                </form>
                            </div>)
                        }
                        {editing && (<div className=" bg-white rounded-lg shadow-2xl p-6 w-[50%]">

                            <form onSubmit={handleUpdate}>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="note">Note</label>
                                    <textarea className='p-1 w-full border border-gray-300' name="note" id="note" cols="30" rows="5" value={formData.note} onChange={handleUpdateChange}></textarea>
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="couponCode">Coupon Code</label>
                                    <input type="text" id="couponCode" name='couponCode' value={formData.couponCode} onChange={handleUpdateChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="from">From</label>
                                    <input type="datetime-local" id="from" name='from' value={formData.from} onChange={handleUpdateChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="to">To</label>
                                    <input type="datetime-local" id="to" name='to' value={formData.to} onChange={handleUpdateChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="noOfTimesAllowed">No Of Times Allowed</label>
                                    <input type="number" id="noOfTimesAllowed" name='noOfTimesAllowed' value={formData.noOfTimesAllowed} onChange={handleUpdateChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="status">Status</label>
                                    <input type="text" id="status" name='status' value={formData.status} onChange={handleUpdateChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                </div>
                                <div className='my-4 text-right'>
                                    <button type='submit' className='bg-blue-500 mr-2 text-white px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300'>Update</button>
                                    <button className='bg-red-500 text-white px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300' onClick={handleCancelUpdate}>Cancel</button>
                                </div>
                            </form>

                        </div>)
                        }
                    </div>
                    <div className='w-full'>
                        {
                            !adding &&
                            !editing &&
                            (
                                <DataTable columns={columns} data={data} />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CouponCode
