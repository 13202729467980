import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";

import Sidebar from "../../components/Sidebar";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { collection, query, getDocs, getDoc, doc, updateDoc, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import axios from "axios";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from "../../components/DataTable";
import Alerts from "../../components/Alerts";
import FilterText from "../../utils/FilterText";
import { updatePassword } from "firebase/auth";
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import { CircularProgress } from "@material-ui/core";

export default function ChannelPartner() {

  const [data, setData] = useState([]);
  const originalDataRef = useRef([]);
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const [isVisibility, setIsVisibility] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    expiredate: 30,
    totalmessages: 30,
    plantype: "Demo",
  });

  let navigate = useNavigate();

  const getUserPlanInfo = async (uid) => {
    let docRef = doc(db, "Channel Partners", uid);
    let userInfo = await getDoc(docRef);
    let pd = "";

    if (userInfo.exists) {
      pd = userInfo.data();

      setFormData(pd);
      setEditing(!editing);
    }

  }

  const handleUpdate = async (event) => {
    event.preventDefault();
    setIsPending(true);

    let {
      personalName,
      mobile,
      email,
      password,
      businessName,
      erpType,
      city,
      confirmPassword
    } = formData;

    try {
      let docRef = doc(db, "Channel Partners", formData.uid);

      let userProfileData = await getDoc(docRef);

      if (userProfileData.exists()) {
        await updateDoc(docRef, {
          ...userProfileData.doc,
          personalName,
          mobile,
          email,
          password,
          businessName,
          erpType,
          city,
          confirmPassword
        });

        await updatePassword({ uid: formData.uid, password });

      } else {
        setError("Not a valid user!")
      }

      setIsPending(false);

      setTimeout(() => {
        setFormData(null);
        setEditing(false);
        setMessage(null);
        fetchUsers();
      }, 1000);

    } catch (error) {
      setError(error.message);
      setIsPending(false);
    }
  };

  const handleEdit = async (e) => {
    await getUserPlanInfo(e.id);
  };

  const handleChange = async (event) => {

    if (
      event.target.name === "endingTo"
    ) {

      setFormData((prevData) => {
        return {
          ...prevData

        };
      });
    } else {
      setFormData((prevData) => {
        return { ...prevData, [event.target.name]: event.target.value };
      });
    }

  }

  const fetchUsers = async () => {

    const q = query(collection(db, "Channel Partners"), orderBy("doj", "desc"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      let {
        email,
        mobile,
        password,
        doj,
        firstName = "",
        personalName = "",
        businessName,
        myReferalCode,
        isDisabled
      } = doc.data();

      if (doc.data()) {
        records.push({
          id: doc.id,
          personalName: firstName ? firstName : personalName,
          businessName,
          mobile,
          email,
          password,
          dateOfJoining:
            doj !== ""
              ? moment(Number(doj)).utc().format("DD-MM-YYYY")
              : "",
          myReferalCode,
          isDisabled
        });
      }

      if (++i === querySnapshotSize) {
        setData(records);
        originalDataRef.current = records;
      }
    });
  };

  const handleDelete = useCallback(async (data) => {
    data = JSON.stringify({
      uid: data.id,
    });

    var config = {
      method: "post",
      url: "https://tallykonnect.com/auth/removechannelpartner",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(async (response) => {
        setData([]);
        await fetchUsers();
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const updateUserStatus = (profile) => {

    console.log("profile", profile)

    let data = JSON.stringify({
      "uid": profile.id,
      "isDisabled": !profile.isDisabled
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://tallykonnect.com/auth/channelpartnersstatus',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setMessage(JSON.stringify(response.data.message));
        setData([]);
        fetchUsers();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const columns = useMemo(
    () => [

      { field: "myReferalCode", headerName: "Referal Code", minWidth: 210, align: "left" },
      { field: "businessName", headerName: "Business", minWidth: 210, align: "left" },
      { field: "personalName", headerName: "Name", minWidth: 210, align: "left" },
      { field: "email", headerName: "Email", minWidth: 210, align: "left" },
      { field: "mobile", headerName: "Mobile", minWidth: 100, align: "left" },
      { field: "password", headerName: "Password", minWidth: 100, align: "left" },
      {
        field: "dateOfJoining",
        headerName: "D.O.J",
        minWidth: 120,
        align: "left",
        format: (value) => value.toLocaleString("en-IN"),
      },
      {
        field: "isDisabled",
        headerName: "Status",
        minWidth: 120,
        align: "left",
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        headerName: "Actions",
        minWidth: 170,
        align: "right",
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              params.row && params.row.isDisabled ? (
                <VoiceOverOffIcon style={{ color: "red" }} />
              ) : (
                <SpatialAudioOffIcon style={{ color: "green" }} />
              )
            }
            label="Disable"
            onClick={() => updateUserStatus(params.row)}
          />,
          < GridActionsCellItem
            icon={< Visibility />}
            label="View"
            onClick={() => navigate(
              "/channelpartnerprofile", { state: { ...params.row } }
            )}
          />,
          < GridActionsCellItem
            icon={< Edit />}
            label="Edit"
            onClick={() => handleEdit(params.row)}
          />,
          < GridActionsCellItem
            icon={< DeleteIcon />}
            label="Delete"
            onClick={() => handleDelete(params.row)}
          />,
        ]
      },
    ], [navigate, handleDelete]);

  useEffect(() => {
    fetchUsers();
  }, [])

  return (
    <div className="flex h-screen w-screen">
      <Sidebar />
      {editing &&
        (<div className="body w-64 flex-1 bg-gray-200 p-8">
          <p className="mb-4 w-full text-center font-sans text-xl text-red-400">
            {FilterText(message)}
          </p>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Edit Personal Information
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Use a permanent address where you can receive mail.
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={handleUpdate} autoComplete="off">
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="personalName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Personal Name
                        </label>
                        <input
                          type="text"
                          name="personalName"
                          id="personalName"
                          value={formData ? formData.personalName : ""}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-orange-300 focus:ring-orange-300 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="businessName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Business Name
                        </label>
                        <input
                          type="text"
                          name="businessName"
                          id="businessName"
                          value={formData ? formData.businessName : ""}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-orange-300 focus:ring-orange-300 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="mobile"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Mobile
                        </label>
                        <input
                          required
                          type="number"
                          name="mobile"
                          id="mobile"
                          value={formData ? formData.mobile : ""}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-orange-300 focus:ring-orange-300 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address / Login ID
                        </label>
                        <input
                          required
                          type="email"
                          name="email"
                          id="email"
                          value={formData ? formData.email : ""}
                          onChange={handleChange}
                          readOnly
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-orange-300 focus:ring-orange-300 sm:text-sm"
                        />
                      </div>
                      <div className="relative col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Password
                        </label>
                        <input
                          required
                          type="password"
                          name="password"
                          id="password"
                          ref={passwordRef}
                          value={formData ? formData.password : ""}
                          minLength="8"
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-orange-300 focus:ring-orange-300 sm:text-sm"
                        />
                        <label
                          htmlFor="passwordVisibility"
                          className="absolute top-[1.7rem] right-0"
                        >
                          {isVisibility && <Visibility />}
                          {!isVisibility && <VisibilityOff />}

                          <input
                            type="checkbox"
                            id="passwordVisibility"
                            style={{ display: "none" }}
                            onChange={() => {
                              setIsVisibility(!isVisibility);
                              if (!isVisibility)
                                passwordRef.current.type = "text";
                              else passwordRef.current.type = "password";
                            }}
                          />
                        </label>
                      </div>
                      <div className="relative col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="confirmPassword"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          ref={confirmPasswordRef}
                          minLength="8"
                          value={
                            formData && formData.confirmPassword
                              ? formData.confirmPassword
                              : ""
                          }
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-orange-300 focus:ring-orange-300 sm:text-sm"
                        />
                        <label
                          htmlFor="confirmPasswordVisibility"
                          className="absolute top-[1.7rem] right-0"
                        >
                          {isVisibility && <Visibility />}
                          {!isVisibility && <VisibilityOff />}

                          <input
                            type="checkbox"
                            id="confirmPasswordVisibility"
                            style={{ display: "none" }}
                            onChange={() => {
                              setIsVisibility(!isVisibility);
                              if (!isVisibility)
                                confirmPasswordRef.current.type = "text";
                              else confirmPasswordRef.current.type = "password";
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          value={formData ? formData.city : ""}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-orange-300 focus:ring-orange-300 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="pinCode"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Pin Code
                        </label>
                        <input
                          type="number"
                          name="pinCode"
                          id="pinCode"
                          value={formData ? formData.pinCode : ""}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-orange-300 focus:ring-orange-300 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    {!isPending && (
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent hover:bg-orange-300 bg-orange-400 py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2"
                      >
                        Update
                      </button>
                    )}
                    {isPending && (
                      <button
                        className="inline-flex justify-center rounded-md border border-transparent bg-orange-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2"
                        disabled
                      >
                        Wait....
                      </button>
                    )}
                    <button
                      onClick={() => setEditing(false)}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    {error && (
                      <Alerts alertType="Error">{error}</Alerts>
                    )}
                    {message && (
                      <Alerts alertType="Success">{message}</Alerts>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>)
      }
      {!editing && <div className="body w-64 flex-1 flex-col bg-white p-8">
        <h1 className="mb-4 rounded bg-purple-400 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
          Channel Partners
        </h1>
        {data.length >= 0 ? (
          <DataTable
            columns={columns}
            data={data}
          />
        ) : (
          <p className="text-xl font-semibold text-red-500 ">
            no records found
          </p>
        )}
      </div>}
    </div>
  );
}

