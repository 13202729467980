import React, { useState, useEffect } from "react";

import Sidebar from "../../components/Sidebar";

import {
    query,
    doc,
    setDoc,
    addDoc,
    deleteDoc,
    collection,
    getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Edit, Visibility } from "@mui/icons-material";
import FilterText from "../../utils/FilterText";
import { useRef } from "react";
import moment from "moment-timezone";

import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';


export default function Demo() {
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);
    const [formData, setFormData] = useState({
        "id": "",
        "name": "",
        "email": "",
        "mobile": "",
        "dateTime": "",
        "status": "",
        "attained": "",
        "executive": "",
        "originalDateTime": "",
        "followupDate": "",
        "followupMessage": ""
    });

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const originalDataRef = useRef([]);
    const [processing, setProcessing] = useState(false);

    const columns = [
        { field: "name", headerName: "Name", minWidth: 170, align: "left" },
        {
            field: "mobile",
            headerName: "Mobile",
            minWidth: 130,
            align: "left"
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 130,
            align: "right",
        },
        {
            field: "dateTime",
            headerName: "Date Time",
            minWidth: 200,
            align: "center",
            sortable: false,
            format: (value) => value.toLocaleString("en-IN"),
        },
        {
            field: "followupDate",
            headerName: "Follow Up Date",
            minWidth: 200,
            align: "center",
        },
        {
            field: "attained",
            headerName: "Attained",
            minWidth: 200,
            align: "center",
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 130,
            align: "center",
            format: (value) => value.toLocaleString("en-IN"),
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "right",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="Edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<Visibility />}
                    label="View"
                    onClick={() => navigate(
                        "/followupDemoDetails", { state: { ...params.row } }
                    )}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDelete(params.row)}
                />,
            ]
        },
    ];

    const handleEdit = (data) => {
        setEditing(!editing);
        setFormData({ ...data });

    };

    const handleDelete = async ({ id }) => {
        try {
            let docRef = doc(db, "Websites", "Client", "Demo", id);
            await deleteDoc(docRef);
            fetchAllDemo();
        } catch (error) {
            setMessage(FilterText(error.message))
        }
    };

    const handleChange = (e) => {
        setFormData((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setProcessing(true);

        let {
            name,
            email,
            mobile,
            status,
            attained,
            executive,
            originalDateTime,
            followupDate = "",
            followupMessage = ""
        } = formData;

        try {
            let docRef = doc(db, "Websites", "Client", "Demo", formData.id);
            let colRef = collection(db, "Websites", "Client", "Demo", formData.id, "FollowUp");

            await setDoc(docRef, {
                name,
                email,
                mobile,
                status,
                attained,
                executive,
                dateTime: formData.originalDateTime,
                followupDate,

            }, { merge: true });

            if (
                followupDate !== "" &&
                followupMessage !== ""
            ) {
                await addDoc(colRef, {
                    followupDate,
                    followupMessage,
                    status,
                    executive,
                    originalDateTime,
                })
            }


            setEditing(!editing);
            setMessage("Record update successfully!");
            setProcessing(false);
        } catch (err) {
            console.log(err);
        }
    };

    //FETCH ALL DEMO REQUESTS
    const fetchAllDemo = async () => {
        const q = query(collection(db, "Websites", "Client", "Demo"));
        const snapshots = await getDocs(q);

        let records = [];
        let querySnapshotSize = snapshots.docs.length;
        let i = 0;

        snapshots.forEach((doc) => {
            //FORMATE DATE OF JOINING START

            if (doc.data()) {
                let {
                    name,
                    email,
                    mobile,
                    dateTime,
                    status,
                    attained,
                    executive,
                    followupDate
                } = doc.data()

                records.push({
                    id: doc.id,
                    name,
                    email,
                    mobile,
                    dateTime,
                    status,
                    attained,
                    executive,
                    followupDate,
                });
            }

            if (++i === querySnapshotSize) {


                originalDataRef.current = records.sort(function (a, b) {
                    return new Date(b.dateTime) - new Date(a.dateTime);
                });

                originalDataRef.current = originalDataRef.current.map(d => {
                    return {
                        ...d, dateTime: moment(d.dateTime).utc()
                            .tz("Asia/Kolkata")
                            .format("DD-MM-YYYY h:mm:ss A"), originalDateTime: d.dateTime
                    }
                })

                originalDataRef.current = originalDataRef.current.map(d => {
                    if (d.followupDate) {
                        return {
                            ...d, followupDate: moment(d.followupDate).utc()
                                .tz("Asia/Kolkata")
                                .format("DD-MM-YYYY hh:mm:ss A")
                        }
                    } else {
                        return d;
                    }
                })

                originalDataRef.current = originalDataRef.current.map(d => {
                    if (d.attained) {
                        return {
                            ...d, attained: moment(d.attained).utc()
                                .tz("Asia/Kolkata")
                                .format("DD-MM-YYYY hh:mm:ss A")
                        }
                    } else {
                        return d;
                    }
                })

                setData(originalDataRef.current);

            }
        });
    };

    useEffect(() => {
        setTimeout(() => {
            setMessage("");
            setError("");
        }, 5000)
    }, [message, error])

    useEffect(() => {
        fetchAllDemo();
    }, [editing]);

    return (
        <div className="flex w-screen">
            <Sidebar />

            {editing && (
                <div className="body w-64 flex-1 bg-gray-200 p-8">
                    <p className="mb-4 w-full text-center font-sans text-xl text-red-500">
                        {FilterText(message)}
                    </p>
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Demo Information
                                </h3>
                                <p className="mt-1 text-sm text-gray-600">
                                    Update Demo
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 md:col-span-2 md:mt-0">
                            <form onSubmit={handleUpdate}>
                                <div className="overflow-hidden shadow sm:rounded-md">
                                    <div className="bg-white px-4 py-5 sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <input
                                                    type="hidden"
                                                    name="id"
                                                    id="id"
                                                    value={formData.id}
                                                    onChange={handleChange}
                                                    autoComplete="id"
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <label
                                                    htmlFor="name"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="mobile"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Mobile
                                                </label>
                                                <input
                                                    type="number"
                                                    name="mobile"
                                                    id="mobile"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="dateTime"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Date Time
                                                </label>
                                                <input
                                                    type="datetime-local"
                                                    name="dateTime"
                                                    id="dateTime"
                                                    value={formData.originalDateTime}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="status"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Status
                                                </label>
                                                <select
                                                    name="status"
                                                    id="status"
                                                    onChange={handleChange}
                                                    value={formData.status}
                                                    className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option value="">Please Select</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Follow Up">Follow Up</option>
                                                    <option value="Not Interested">Not Interested</option>
                                                    <option value="Done">Done</option>

                                                </select>
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="attained"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Attained
                                                </label>
                                                <input
                                                    type="datetime-local"
                                                    name="attained"
                                                    id="attained"
                                                    value={formData.attained}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="executive"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Executive (Full Name)
                                                </label>
                                                <input
                                                    type="text"
                                                    name="executive"
                                                    id="executive"
                                                    value={formData.executive}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <hr className="col-span-6" />
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="followupDate"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Follow Up Date
                                                </label>
                                                <input
                                                    type="datetime-local"
                                                    name="followupDate"
                                                    id="followupDate"
                                                    value={formData.followupDate}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="followupMessage"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Follow Up Message
                                                </label>
                                                <textarea
                                                    cols="30"
                                                    rows="4"
                                                    type="text"
                                                    name="followupMessage"
                                                    id="followupMessage"
                                                    value={formData.followupMessage}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                >
                                                </textarea>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                        {!processing ? (
                                            <button
                                                type="submit"
                                                value="Submit"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-purple-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Save
                                            </button>
                                        ) : (
                                            <button
                                                disabled={processing}
                                                className="inline-flex justify-center rounded-md border border-transparent bg-purple-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Wait..
                                            </button>
                                        )}

                                        <button
                                            type="submit"
                                            value="Submit"
                                            onClick={() => setEditing(false)}
                                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {

                !editing &&
                (
                    <div className="body w-64 flex-1 bg-white p-8">
                        <h1 className="mb-4 rounded bg-purple-400 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
                            DEMO REQUESTS
                        </h1>

                        {data && (
                            <DataTable data={data} columns={columns} />
                        )}
                        {!data && <p>No record found :(</p>}

                    </div>
                )}
        </div>
    );
}
